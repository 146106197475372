<template>
  <div>
    <div v-if="loading">Cargando ...</div>
    <div v-else-if="!canAnswer">
      <h4>Usted no tiene permiso para contestar el instumento.</h4>
    </div>
    <div v-else>
      <template v-if="evaluation">
        <template v-if="isStartDate()">
          <h4>
            La evaluación comenzará el "<strong>{{
              new Date(this.evaluation.start_date) | FormatToDateTime
            }}</strong
            >"
            <div class="time-label-start start-time-label-start">
              Dentro de:
            </div>
            <div class="time-card-start">
              <Timer :end_date="this.evaluation.start_date" />
            </div>
          </h4>
        </template>
        <template v-else-if="evaluation.evaluation_instrument <= 4">
          <template v-if="questionTest">
            <AnswerQuestionTest
              :question_test_id="questionTest.id"
            ></AnswerQuestionTest>
          </template>
          <template v-else>
            <div>El examen no esta desarrollado.</div>
          </template>
        </template>
        <template v-else-if="evaluation.evaluation_instrument >= 5">
          <template v-if="rubric">
            <ObservationInstrument
              :rubric_id="rubric.id"
            ></ObservationInstrument>
          </template>
          <template v-else>
            <div>El examen no esta desarrollado.</div>
          </template>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";

export default {
  name: "AnswerView",
  components: {
    AnswerQuestionTest: () =>
      import("@/components/evaluations/Test/Answer/AnswerQuestionTest"),
    ObservationInstrument: () =>
      import("./ObservationInstrument/ObservationInstrument"),
    Timer: () => import("../reusable/Timer.vue"),
  },
  data() {
    return {
      evaluation_id: this.$route.params.evaluation_id,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      studyUnits: names.STUDY_UNITS,
      evaluations: names.EVALUATIONS,
      evaluationInstruments: names.EVALUATION_INSTRUMENTS,
      rubrics: names.OBSERVATION_INSTRUMENTS,
      questionTests: names.QUESTION_TESTS,
      learningResults: names.LEARNING_RESULTS,
      sections: names.SECTIONS,
      user: "getUser",
    }),
    evaluation() {
      if (!this.evaluation_id) return null;
      return this.evaluations.find((x) => x.id == this.evaluation_id);
    },
    section() {
      if (!this.evaluation) return null;
      return this.sections.find((x) => x.id == this.evaluation.section);
    },
    questionTest() {
      if (!this.evaluation) return null;
      return this.questionTests.find((x) => x.evaluation == this.evaluation.id);
    },
    rubric() {
      if (!this.evaluation) return null;
      return this.rubrics.find((x) => x.evaluation == this.evaluation.id);
    },
    canAnswer() {
      if (!this.section || !this.user) return false;
      let flag = false;
      this.section.students.forEach((user_id) => {
        if (this.user.id == user_id) {
          flag = true;
          return true;
        }
      });
      return flag;
    },
  },
  methods: {
    isStartDate() {
      let date_usuario = new Date();
      let start_date_evaluation = new Date(this.evaluation.start_date);
      if (date_usuario < start_date_evaluation) {
        return true;
      } else return false;
    },
    fetchEveryThing() {
      this.loading = true;
      this.$store
        .dispatch(names.FETCH_EVALUATION, this.evaluation_id)
        .then((evaluation) => {
          this.$store
            .dispatch(names.FETCH_SECTION, evaluation.section)
            .then(() => {
              this.loading = false;
            });
          if (evaluation.evaluation_instrument <= 4) {
            this.$store.dispatch(names.FETCH_QUESTION_TESTS, evaluation.id);
          } else if (evaluation.evaluation_instrument >= 5) {
            this.$store.dispatch(names.FETCH_OBSERVATION_INSTRUMENTS, evaluation.id);
          }
          if (evaluation) {
            if (!evaluation.finished) {
              let date_usuario = new Date();
              let start_date_evaluation = new Date(this.evaluation.start_date);
              let end_date_evaluation = new Date(this.evaluation.end_date);
              if (date_usuario < start_date_evaluation) {
                let start_day =
                  start_date_evaluation.getDay() - date_usuario.getDay();
                let start_hours =
                  start_date_evaluation.getHours() - date_usuario.getHours();
                let start_minutes =
                  start_date_evaluation.getMinutes() -
                  date_usuario.getMinutes();
                let start_seconds =
                  start_date_evaluation.getSeconds() -
                  date_usuario.getSeconds();
                let start_milisegundos =
                  start_day * 86400000 +
                  start_hours * 3600000 +
                  start_minutes * 60000 +
                  start_seconds * 1000;
                if (this.timeout) clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                  alert("En este momento se dará inicio de la evaluación.");
                  location.reload();
                }, start_milisegundos);
              } else if (date_usuario <= end_date_evaluation) {
                let end_day =
                  end_date_evaluation.getDay() - date_usuario.getDay();
                let end_hours =
                  end_date_evaluation.getHours() - date_usuario.getHours();
                let end_minutes =
                  end_date_evaluation.getMinutes() - date_usuario.getMinutes();
                let end_seconds =
                  end_date_evaluation.getSeconds() - date_usuario.getSeconds();
                let end_milisegundos =
                  end_day * 86400000 +
                  end_hours * 3600000 +
                  end_minutes * 60000 +
                  end_seconds * 1000;
                if (this.timeout) clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                  alert("El tiempo para realizar la evaluación finalizo.");
                  location.reload();
                }, end_milisegundos);
              }
            }
          }
        });
    },
  },
  watch: {
    "$route.params.evaluation_id": function () {
      if (this.evaluation_id != this.$route.params.evaluation_id) {
        this.evaluation_id = this.$route.params.evaluation_id;
        this.fetchEveryThing();
      }
    },
  },
  created() {
    this.$store.dispatch(names.FETCH_EVALUATION_INSTRUMENTS);
    this.fetchEveryThing();
  },
};
</script>

<style>
.time-label-start {
  color: white;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 4px;
  margin-right: 7px;
}
.start-time-label-start {
  background-color: rgb(235, 70, 70);
}
.time-card-start {
  margin-right: 10px;
  font-size: 150px;
}
</style>